// src/utils/apiClient.js
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "/api";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to attach token
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    // Use "Authorization: Bearer <token>"
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiClient;
