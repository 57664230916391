// frontend/src/App.js

import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { CartProvider } from "./context/CartContext";
import { UserProvider } from "./context/UserContext";
import NavBar from "./components/NavBar";
import Loading from "./components/Loading"; // or your spinner/loading UI
import ProtectedRoute from "./components/ProtectedRoute"; // IMPORTANT: new import
import "./styles/global.css";

// Lazy-loaded pages and components
const Home = lazy(() => import("./pages/home"));
const ShippingForm = lazy(() => import("./components/ShippingForm"));
const TrackingForm = lazy(() => import("./components/TrackingForm"));
const Cart = lazy(() => import("./components/Cart"));
const Register = lazy(() => import("./pages/Register"));
const Login = lazy(() => import("./pages/Login"));
const NotFound = lazy(() => import("./pages/NotFound"));
const PaymentResult = lazy(() => import("./components/PaymentResult"));
const CashAppReturn = lazy(() => import("./pages/CashAppReturn"));
const UserProfile = lazy(() => import("./pages/UserProfile"));

function App() {
  return (
    <Router>
      <UserProvider>
        <CartProvider>
          <ErrorBoundary>
            <NavBar />
            <Suspense fallback={<Loading />}>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/get-quote" element={<ShippingForm />} />
                <Route path="/track-shipment" element={<TrackingForm />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />

                {/* Protected Routes */}
                <Route
                  path="/cart"
                  element={
                    <ProtectedRoute>
                      <Cart />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />

                {/* Payment + Return routes - decide if they're public or protected */}
                <Route path="/payment-result" element={<PaymentResult />} />
                <Route path="/cashapp-return" element={<CashAppReturn />} />

                {/* Catch-All / 404 */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </CartProvider>
      </UserProvider>
    </Router>
  );
}

export default App;
