// src/context/UserContext.js
import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import apiClient from "../utils/apiClient";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) return; // no token, so user remains null

    // Attempt to fetch user info
    apiClient
      .get("/auth/user")
      .then((res) => {
        setUser({ ...res.data, token });
      })
      .catch((err) => {
        console.error("Token validation failed:", err?.response?.data || err);
        localStorage.removeItem("token");
        setUser(null);
      });
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
