// frontend/src/components/ProtectedRoute.js

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";

/**
 * Wrap any <Route> that you want to protect behind a login.
 * If the user is not logged in, we redirect them to "/login".
 * If they are logged in, we render the child component normally.
 */
function ProtectedRoute({ children }) {
  const { user } = useContext(UserContext);

  // If user is null or missing a valid token, redirect to login
  if (!user || !user.token) {
    return <Navigate to="/login" replace />;
  }

  // Otherwise, render the protected page
  return children;
}

export default ProtectedRoute;
